import React from 'react';
import { Link, graphql } from 'gatsby';
import InViewMonitor from 'react-inview-monitor';
import Slider from 'react-slick';
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import Markdown from 'react-markdown';
import SEO from '../components/SEO';
import Layout from '../components/layout';
import PageHeader from '../components/Sections/PageHeader';
import RelatedProjects from '../components/Sections/RelatedProjects';
import QuickForm from '../components/Sections/QuickForm';
import DownloadFile from '../components/Sections/DownloadFile';

const sectionTitle = 'Lavori svolti';

const ProjectTemplate = ({ data, location }) => {
  const project = data.datoCmsProject;

  return (
    <Layout>
      <SEO
        title={project.seoTags !== null ? project.seoTags.title : project.name}
        desc={project.seoTags !== null ? project.seoTags.description : project.name}
        pathname={location.pathname}
        node={project}
        article
      />
      <PageHeader title={sectionTitle} />
      <div id="page" className="section">
        <Container>
          <Row>
            <Col sm="12" className="text-center">
              <h1 className="h2">{project.name}</h1>
            </Col>
            <Col sm="12">
              {project.gallery.length > 1 ? (
                <Slider infinite autoplay arrows>
                  {project.gallery.map(({ sizes }) => (
                    <img src={sizes.src} alt={project.title} key={sizes.src} />
                  ))}
                </Slider>
              )
                : (
                  <img src={project.gallery.length > 0 ? project.gallery[0].sizes.src : null} className="img-fluid" />
                )
            }
            </Col>
          </Row>
          <div className="section pb-0">
            <Row>
              <Col sm="8">
                <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp"><Markdown source={project.description} /></InViewMonitor>
              </Col>
              <Col sm="4">
                <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
                  <aside className="sidebar mt-5 mt-sm-0">
                    <ListGroup>
                      {!project.customer ? null
                        : (
                          <ListGroupItem>
                            <ListGroupItemHeading className="mb-0">Committente</ListGroupItemHeading>
                            <ListGroupItemText className="text-primary font-weight-normal">{project.customer}</ListGroupItemText>
                          </ListGroupItem>
                        )
                      }
                      {!project.year ? null
                        : (
                          <ListGroupItem>
                            <ListGroupItemHeading className="mb-0">Anno</ListGroupItemHeading>
                            <ListGroupItemText className="text-primary font-weight-normal">{project.year}</ListGroupItemText>
                          </ListGroupItem>
                        )
                      }
                      {!project.location ? null
                        : (
                          <ListGroupItem>
                            <ListGroupItemHeading className="mb-0">Località</ListGroupItemHeading>
                            <ListGroupItemText className="text-primary font-weight-normal">{project.location}</ListGroupItemText>
                          </ListGroupItem>
                        )
                      }
                      <ListGroupItem>
                        <ListGroupItemHeading className="mb-0">Tipologia</ListGroupItemHeading>
                        {project.category.map(c => <ListGroupItemText><Link to={`/progetti/tipologia/${c.slug}`}>{c.name}</Link></ListGroupItemText>)}

                      </ListGroupItem>
                    </ListGroup>
                  </aside>
                  <DownloadFile document={project.document} />
                </InViewMonitor>
              </Col>
            </Row>
          </div>
        </Container>
        <RelatedProjects relatedProjects={project.relatedProjects} />
      </div>
      <QuickForm />
    </Layout>
  );
};


export default ProjectTemplate;

export const query = graphql`
  query ProjectTemplate($slug: String!) {
    datoCmsProject(slug: {eq: $slug}) {
      name
      description
      customer
      year
      location
      category {
        name
        slug
      }
      gallery {
        sizes(imgixParams: {fm: "jpg", crop: "focalpoint",  fit: "crop", h: "600", w: "1600"}, maxWidth: 1600, maxHeight: 450) {
          src
        }
      }
      document {
        url
      }
      seoTags {
        title
        description
      }
      relatedProjects {
      id
      slug
      name
      category {
        name
      }
      gallery {
        sizes (imgixParams: {fm: "jpg", crop: "focalpoint",  fit: "crop", h: "300", w: "600"}) {
          src
        }
      }
     }
    }
  }
`;

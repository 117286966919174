import React from 'react';
import PropTypes from 'prop-types';

const DownloadFile = ({ document }) => (
  !document ? null
    : (
      <div className="mt-5">
        <a href={document.url} className="btn btn-outline-primary btn-block btn-icon" rel="noopener noreferrer" target="_blank">
Scarica scheda
          {' '}
          <i className="icon-arrow-down" />
        </a>
      </div>
    )
);

DownloadFile.propTypes = {
  document: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};

DownloadFile.defaultProps = {
  document: undefined,
};

export default DownloadFile;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import InViewMonitor from 'react-inview-monitor';
import {
  Container,
  Row,
  Card,
  CardImg,
  CardImgOverlay,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';


const RelatedProjects = ({ relatedProjects }) => (
  relatedProjects.length > 0 ? (
    <div id="related" className="section pb-0">
      <Container>
        <div className="section-header mb-5">
          <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp"><h2 className="text-uppercase">Progetti correlati</h2></InViewMonitor>
        </div>
        <Row>
          {relatedProjects.map(related => (
            <InViewMonitor key={related.name} classNameNotInView="col-sm-4 animate-box" classNameInView="col-sm-4 animated animated-effect fadeInUp">
              <Card className="bg-dark text-white">
                <CardImg width="100%" src={related.gallery.length > 0 ? related.gallery[0].sizes.src : 'https://placeimg.com/600/300/nature'} alt={related.name} />
                <CardImgOverlay className="d-flex align-items-end">
                  <CardBody>
                    <CardText tag="h6">{related.category.name}</CardText>
                    <CardTitle tag="h4">{related.name}</CardTitle>
                    <Link to={`/progetti/${related.slug}`} className="stretched-link" />
                  </CardBody>
                </CardImgOverlay>
              </Card>
            </InViewMonitor>
          ))}
        </Row>
      </Container>
    </div>
  )
    : null
);

RelatedProjects.propTypes = {
  relatedProjects: PropTypes.arrayOf(PropTypes.shape({})),
};

RelatedProjects.defaultProps = {
  relatedProjects: undefined,
};

export default RelatedProjects;
